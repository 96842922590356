/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Button, Image, ColumnCover, ColumnDiv, Text } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-11ny9cn bg--center --full" name={"einleitung"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"} style={{"maxWidth":1280}}>
              
              <Title className="title-box title-box--shadow4 fs--102" content={"<span style='color: white'>Einführungskonsultation<span style='color: var(--color-variable-1);'>kostenlos</span>.</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--36 mt--20" content={"<span style='color: white'>Persönliche Trainings schon ab 10€ / St.</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--24 swpf--uppercase mt--30" href={"/de/kontakt"} content={"Sitzung buchen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"einleitung-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--1 pb--20 pl--25 pr--25 pt--20" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Im gesunden Körper ein gesunder Geist"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style=\"color: var(--color-blend--50);\">Schreiben Sie uns +49 797 811 2X05</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"[[UNIsecionname10]]"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--3 --full flex--center" anim={"2"} animS={"4"} style={{"maxWidth":1540}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/107/img-2_ove=000000x35_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/107/img-2_ove=000000x35_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/107/img-2_ove=000000x35_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/107/img-2_ove=000000x35_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/107/img-2_ove=000000x35_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/107/img-2_ove=000000x35_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box fs--36 w--500 swpf--uppercase" style={{"maxWidth":200}} content={"<span style='color: white'>Gruppenlektionen </span>"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/107/img-3_ove=000000x35_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/107/img-3_ove=000000x35_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/107/img-3_ove=000000x35_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/107/img-3_ove=000000x35_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/107/img-3_ove=000000x35_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/107/img-3_ove=000000x35_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box fs--36 w--500 swpf--uppercase" style={{"maxWidth":200}} content={"<span style='color: white'>Persönliche Trainings </span>"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/107/img-1_ove=000000x35_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/107/img-1_ove=000000x35_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/107/img-1_ove=000000x35_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/107/img-1_ove=000000x35_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/107/img-1_ove=000000x35_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/107/img-1_ove=000000x35_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box fs--36 w--500 swpf--uppercase" style={{"maxWidth":200}} content={"<span style='color: white'>Online Trainings</span>"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"information"}>
          
          <ColumnWrap className="column__flex el--2 pb--20 pl--25 pr--25 pt--20 flex--top" anim={null} animS={null} style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s4" anim={"5"} animS={"4"} style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Wenn Sie Ergebnisse wollen, <span style=\\\"font-style: italic;\\\">sollte Ihnen das Training Spass machen</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s4" anim={"4"} animS={"4"}>
              
              <Subtitle className="subtitle-box w--400" content={"<span style='color: var(--color-variable-1);'>Im gesunden Körper ein gesunder Geist</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":560}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":560}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1jz76ti --style3 bg--center --full" name={"kontakt"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"} style={{"maxWidth":1280}}>
              
              <Title className="title-box fs--72" content={"<span style='color: white'>Einführungskonsultation<span style='color: var(--color-variable-1);'>kostenlos</span>.</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--30 mt--20" content={"<span style='color: white'>Persönliche Trainings schon ab 10€ / St.</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--20 swpf--uppercase mt--30" href={"/de/kontakt"} content={"Sitzung buchen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pl--25 pr--25 pt--10" style={{"maxWidth":1280}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24 w--600 swpf--uppercase" content={"<span style='color: var(--color-variable-1);'>Ivan Kräuslich</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 mt--02" content={"<span style='color: var(--color-dominant)'>Personal Trainer</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/de/"} content={"<span style='color: var(--color-dominant)'>Einleitung</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/de/dienstleistungen"} content={"<span style='color: var(--color-dominant)'>Dienstleistungen</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/de/referenzen"} content={"<span style='color: var(--color-dominant)'>Referenzen</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/de/kontakt"} content={"<span style='color: var(--color-dominant)'>Kontakt</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}